<template>
  <div class='popup-container'>
    <div class='title-container'>
      <h4>{{ title }}</h4>
      <button @click="$emit('close')"><span class='visually-hidden'>Close</span></button>
    </div>
    <div class='content'>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    title: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.popup-container {
  background: rgba(12, 31, 41, 0.8);
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 600;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.188rem 0.375rem rgba(12, 31, 41, 0.25);
  min-width: 20em;
}

.title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

button {
  margin: 0;
  padding: 0;
  background-image: url('~@/assets/svg/close-button.svg');
  background-color: white;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  margin-right: 0;
  margin-left: auto;
  border: none;
}
</style>